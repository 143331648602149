<!--
 * @Author: your name
 * @Date: 2021-04-27 16:57:33
 * @LastEditTime: 2021-04-27 16:58:18
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \jlgh\src\components\navigation.vue
-->
<template>
    <div>
        <div class="nac">
            <van-icon v-if="noleft!=true" @click="$router.go(-1)" size=".35rem" name="arrow-left"/>
            <div class="xxm">{{ msg }}</div>
            <van-icon v-if="right" size=".35rem" name="arrow"/>
            <slot></slot>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            right: Boolean,
            msg: String,
            noleft:Boolean
        },
    };
</script>
<style scoped>
    .xxm {
        position: absolute;
        margin-left: 50%;
        transform: translateX(-50%);
    }

    .nac > :first-child {
        margin-left: 0.32rem;
    }

    .nac > :last-child {
        margin-right: 0.32rem;
    }

    .nac {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #ffffff;
        font-size: 0.34rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 0.48rem;
        padding: 0.3rem 0;
        position: relative;
        /* border-bottom-color: #ebedf0;
        border-bottom-style: solid;
        border-bottom-width: 1px; */
    }
</style>
